import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { Box } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data_example } from "../../Pages/SeatingPlansPage/data_example";

const CustomCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fill: "transparent", // Make the checkbox background transparent
    stroke: "white", // Set the border color to white
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      fill: "white", // Set the tick color to white when checked
      stroke: "white", // Keep the border color white when checked
    },
  },
});

const StyledTableCell = styled(TableCell)({
  padding: "0 5px",
  border: "none", // Ensure there are no borders
});

const StyledButton = styled(Button)({
  backgroundColor: "#3CAF2C",
  color: "white",
  borderRadius: "20px",
  margin: "10px",
});

interface SeatingCell {
  price: number | null;
  status: "open" | "invitation" | "closed" | "";
  ordered: "yes" | "no" | "";
  category: string;
}

interface SeatingTableProps {
  seatingData: {
    [key: string]: {
      [key: string]: SeatingCell;
    };
  };
  onSeatingDataChange: (updatedSeatingData: {
    [key: string]: {
      [key: string]: SeatingCell;
    };
  }) => void;
}

function addMissingNumbers(arr: string[]): string[] {
  const numArr: number[] = arr.map(Number);
  const min: number = Math.min(...numArr);
  const max: number = Math.max(...numArr);
  const currentNumbers: Set<number> = new Set(numArr);
  const completeRange: number[] = Array.from(
    { length: max - min + 1 },
    (_, i) => i + min
  );
  const missingNumbers: number[] = completeRange.filter(
    (num) => !currentNumbers.has(num)
  );
  const missingNumbersAsString: string[] = missingNumbers.map(String);
  return [...arr, ...missingNumbersAsString].sort(
    (a, b) => Number(a) - Number(b)
  );
}
const EventSeatingPlanTable: React.FC<SeatingTableProps> = ({
  seatingData,
  onSeatingDataChange,
}) => {
  const [selectedCells, setSelectedCells] = useState<{
    [key: string]: { [key: string]: SeatingCell };
  }>({});

  const rowArr = new Array(17).fill(0);
  const colArr = new Array(19).fill(0);

  const handleCellClick = (rowIndex: string, colIndex: string) => {
    const tempObj = { ...selectedCells };

    if (!tempObj[rowIndex]) {
      tempObj[rowIndex] = {};
    }

    if (tempObj[rowIndex][colIndex]) {
      delete tempObj[rowIndex][colIndex];
      if (Object.keys(tempObj[rowIndex]).length === 0) {
        delete tempObj[rowIndex];
      }
    } else {
      const cellData = seatingData[rowIndex]?.[colIndex] || {
        price: null,
        status: "open",
        ordered: "",
        category: "",
      };
      tempObj[rowIndex][colIndex] = { ...cellData, category: "aqua" };
    }

    setSelectedCells(tempObj);
  };

  const handleRowCheckboxChange = (rowIndex: string) => {
    setSelectedCells((prevSelectedCells: any) => {
      const newSelectedCells = { ...prevSelectedCells };
      const currentColumns =
        data_example[rowIndex as keyof typeof data_example];

      if (
        newSelectedCells[rowIndex] &&
        Object.keys(newSelectedCells[rowIndex]).length ===
          Object.keys(currentColumns).length
      ) {
        delete newSelectedCells[rowIndex];
      } else {
        newSelectedCells[rowIndex] = {};
        Object.keys(currentColumns).forEach((colIndex) => {
          newSelectedCells[rowIndex][colIndex] = {
            price: seatingData[rowIndex]?.[colIndex]?.price || null,
            status: "open",
            ordered: "no",
            category: "aqua",
          };
        });
      }

      return newSelectedCells;
    });
  };

  const handleSelectForInvitation = () => {
    const updatedSeatingData = { ...seatingData };
    for (const rowIndex in selectedCells) {
      for (const colIndex in selectedCells[rowIndex]) {
        updatedSeatingData[rowIndex][colIndex] = {
          ...updatedSeatingData[rowIndex][colIndex],
          status: "invitation",
          ordered: "yes",
          category: "red",
        };
      }
    }
    setSelectedCells({});
    onSeatingDataChange(updatedSeatingData);
    toast.success("Selected seats are now marked for invitation!");
  };

  const handleCloseSeats = () => {
    const updatedSeatingData = { ...seatingData };
    for (const rowIndex in selectedCells) {
      for (const colIndex in selectedCells[rowIndex]) {
        updatedSeatingData[rowIndex][colIndex] = {
          ...updatedSeatingData[rowIndex][colIndex],
          status: "closed",
          category: "#D3D3D3",
        };
      }
    }
    setSelectedCells({});
    onSeatingDataChange(updatedSeatingData);
    toast.info("Selected seats are now closed!");
  };

  const isRowSelected = (rowIndex: string) => {
    return colArr.every(
      (_, colIndex) =>
        selectedCells[rowIndex]?.[colIndex.toString()] ||
        rowIndex === "8" ||
        colIndex.toString() === "9" ||
        (rowIndex === "16" && colIndex > 6 && colIndex < 12)
    );
  };
  const arrayOfLength18 = Array.from({ length: 18 }, () => 0);
  const rowsIndexArr = Object.keys(data_example);
  return (
    <div
      style={{
        padding: "10px",
        width: "80%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ToastContainer />
      <div style={{ marginBottom: "20px" }}>
        <StyledButton
          variant="contained"
          onClick={handleSelectForInvitation}
          style={{
            marginRight: "10px",
            backgroundColor: "#3CAF2C",
            borderRadius: "40px",
          }}
        >
          Select for Invitation
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={handleCloseSeats}
          style={{
            marginRight: "10px",
            backgroundColor: "#3CAF2C",
            borderRadius: "40px",
          }}
        >
          Close Seats
        </StyledButton>
      </div>
      <Paper
        style={{
          width: "70%",
          maxWidth: "800px",
          backgroundColor: "#9F835A",
          paddingRight: "30px",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            marginLeft: "60px",
          }}
        >
          <svg
            width="450"
            height="62"
            viewBox="0 0 604 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 56C121.179 -6.02401 460 -15.1532 598 55.9999"
              stroke="white"
              strokeWidth="12"
              strokeLinecap="round"
            />
          </svg>
          <Box
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "white",
              position: "absolute",
              top: "25px",
            }}
          >
            Stage
          </Box>
        </Box>
        <Table size="small">
          <TableBody>
            {rowsIndexArr?.map((row_index) => {
              const currentColumns =
                seatingData?.[row_index as keyof typeof seatingData];
              if (row_index === "8") {
                return (
                  <React.Fragment key={`row-${row_index}`}>
                    <TableRow
                      key={`row-${row_index}-10`}
                      style={{ height: "30px" }}
                    >
                      <StyledTableCell padding="checkbox">
                        <CustomCheckbox
                          size="small"
                          checked={
                            selectedCells[row_index]
                              ? Object.keys(selectedCells[row_index]).length ===
                                16
                              : false
                          }
                          onChange={() => handleRowCheckboxChange(row_index)}
                        />
                      </StyledTableCell>
                      {currentColumns &&
                        Object.keys(currentColumns).map((column_index) => {
                          if (column_index === "9")
                            return (
                              <React.Fragment
                                key={`row-${row_index}-col-${column_index}`}
                              >
                                <TableCell
                                  key={`row-${row_index}-col-${column_index}-1`}
                                  style={{
                                    backgroundColor: "transparent",
                                    padding: 0,
                                    textAlign: "center",
                                    border: "none",
                                    width: "20px",
                                    position: "relative",
                                  }}
                                >
                                  {row_index}
                                </TableCell>
                                <TableCell
                                  key={`row-${row_index}-col-${column_index}-2`}
                                  style={{
                                    padding: 0,
                                    textAlign: "center",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    width: "20px",
                                    position: "relative",
                                  }}
                                  onClick={() => {
                                    handleCellClick(row_index, column_index);
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        selectedCells[row_index]?.[column_index]
                                          ?.category === "aqua"
                                          ? "aqua"
                                          : currentColumns[
                                              column_index as keyof typeof currentColumns
                                            ].category || "white",
                                      textAlign: "center",
                                      fontSize: "8px",
                                      margin: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "20px",
                                      height: "20px",
                                      borderTopLeftRadius: "10px",
                                      borderTopRightRadius: "10px",
                                    }}
                                  ></div>
                                </TableCell>
                              </React.Fragment>
                            );
                          else {
                            return (
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-3`}
                                onClick={() => {
                                  handleCellClick(row_index, column_index);
                                }}
                                style={{
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      selectedCells[row_index]?.[column_index]
                                        ?.category === "aqua"
                                        ? "aqua"
                                        : currentColumns[
                                            column_index as keyof typeof currentColumns
                                          ].category || "white",
                                    textAlign: "center",
                                    fontSize: "8px",
                                    margin: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "20px",
                                    height: "20px",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                ></div>
                              </TableCell>
                            );
                          }
                        })}
                    </TableRow>
                    <TableRow
                      key={`row-${row_index}-11`}
                      style={{ height: "30px" }}
                    >
                      {arrayOfLength18.map((_, index) => (
                        <TableCell
                          key={`row-${row_index}-col-${index}-4`}
                          style={{
                            backgroundColor: "transparent",
                            padding: 0,
                            textAlign: "center",
                            border: "none",
                            cursor: "pointer",
                            width: "20px",
                            position: "relative",
                          }}
                        ></TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                );
              } else if (row_index === "16") {
                return (
                  <TableRow key={`row-${row_index}`} style={{ height: "30px" }}>
                    <StyledTableCell padding="checkbox">
                      <CustomCheckbox
                        size="small"
                        checked={
                          selectedCells[row_index]
                            ? Object.keys(selectedCells[row_index]).length ===
                              Object.keys(currentColumns).length
                            : false
                        }
                        onChange={() => handleRowCheckboxChange(row_index)}
                      />
                    </StyledTableCell>
                    {addMissingNumbers(Object.keys(currentColumns))?.map(
                      (column_index) => {
                        if (["7", "8", "10"].includes(column_index)) {
                          return (
                            <TableCell
                              key={`row-${row_index}-col-${column_index}-missing`}
                              style={{
                                backgroundColor: "transparent",
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                            ></TableCell>
                          );
                        } else if (column_index === "9") {
                          return (
                            <React.Fragment
                              key={`row-${row_index}-col-${column_index}`}
                            >
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-2`}
                                style={{
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {row_index}
                              </TableCell>
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-1`}
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                }}
                              ></TableCell>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <TableCell
                              key={`row-${row_index}-col-${column_index}`}
                              style={{
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                              onClick={() => {
                                handleCellClick(row_index, column_index);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    selectedCells[row_index] &&
                                    selectedCells[row_index][column_index]
                                      ? selectedCells[row_index][column_index]
                                          .category
                                      : currentColumns[
                                          column_index as keyof typeof currentColumns
                                        ].category || "white",
                                  textAlign: "center",
                                  fontSize: "8px",
                                  margin: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "20px",
                                  height: "20px",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                }}
                              ></div>
                            </TableCell>
                          );
                        }
                      }
                    )}
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={`row-${row_index}`} style={{ height: "30px" }}>
                    <StyledTableCell padding="checkbox">
                      <CustomCheckbox
                        size="small"
                        checked={
                          selectedCells[row_index]
                            ? Object.keys(selectedCells[row_index]).length ===
                              16
                            : false
                        }
                        onChange={() => handleRowCheckboxChange(row_index)}
                      />
                    </StyledTableCell>
                    {currentColumns &&
                      Object.keys(currentColumns).map((column_index) => {
                        if (column_index === "9") {
                          return (
                            <React.Fragment
                              key={`row-${row_index}-col-${column_index}`}
                            >
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-5`}
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                }}
                              >
                                {row_index}
                              </TableCell>
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-6`}
                                style={{
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  width: "20px",
                                  position: "relative",
                                }}
                                onClick={() => {
                                  handleCellClick(row_index, column_index);
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      selectedCells[row_index]?.[column_index]
                                        ?.category === "aqua"
                                        ? "aqua"
                                        : currentColumns[
                                            column_index as keyof typeof currentColumns
                                          ].category || "white",
                                    textAlign: "center",
                                    fontSize: "8px",
                                    margin: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "20px",
                                    height: "20px",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                ></div>
                              </TableCell>
                            </React.Fragment>
                          );
                        } else
                          return (
                            <TableCell
                              key={`row-${row_index}-col-${column_index}-7`}
                              style={{
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                              onClick={() => {
                                handleCellClick(row_index, column_index);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    selectedCells[row_index]?.[column_index]
                                      ?.category === "aqua"
                                      ? "aqua"
                                      : currentColumns[
                                          column_index as keyof typeof currentColumns
                                        ].category || "white",
                                  textAlign: "center",
                                  fontSize: "8px",
                                  margin: "auto",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "20px",
                                  height: "20px",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                }}
                              ></div>
                            </TableCell>
                          );
                      })}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default EventSeatingPlanTable;