import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
  Backdrop,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { setCurrentEvent } from "../../reducers/event_reducer";
import { setHallsList } from "../../reducers/hall_reducer";
import events_service from "../../services/events_service";
import halls_service from "../../services/halls_service";
import EventSeatingPlanTable from "../../Components/SeatingPlanComponents/EventSeatingPlanTable";
import seating_plan_service from "../../services/seating_plan_service";
import { toast } from "react-toastify";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    paddingBottom: theme.spacing(2),
  },
  container: {
    width: "98%",
    height: "95%",
    backgroundColor: "#fff",
    padding: "20px",
    overflowY: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#4D67EB",
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3755a3",
    },
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  textField: {
    width: "100%",
    fontSize: "12px",
  },
  selectField: {
    width: "100%",
  },
  loaderBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  imagePreview: {
    width: "100%",
    maxHeight: "150px",
    marginTop: theme.spacing(2),
    objectFit: "contain",
  },
  languageTabs: {
    display: "flex",
    alignItems: "center",
  },
  flag: {
    width: "24px",
    height: "24px",
    marginRight: theme.spacing(1),
  },
  errorText: {
    color: "#fff",
    backgroundColor: "rgba(255, 0, 0, 0.4)",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
  },
}));

interface SeatingCell {
  price: number | null;
  status: "open" | "invitation" | "closed" | "";
  ordered: "yes" | "no" | "";
  category: string;
}

interface FormData {
  eventName_en: string;
  eventName_am: string;
  description_en: string;
  description_am: string;
  eventImage: File | string | null;
  dateTime: string;
  eventCategory: string;
  hallID: string;
  seatingPlanID: string;
  status: string;
  [key: string]: any;
}

const EventsCreationPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hallsReducer } = useSelector((state: RootState) => state);
  const { hallsList } = hallsReducer;
  const { id } = useParams<{ id: string }>();

  const [formData, setFormData] = useState<FormData>({
    eventName_en: "",
    eventName_am: "",
    description_en: "",
    description_am: "",
    eventImage: null,
    dateTime: "",
    eventCategory: "",
    hallID: "",
    seatingPlanID: "",
    status: "Draft",
  });

  // Updated errors state
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({
    eventName_en: false,
    eventName_am: false,
    dateTime: false,
    eventCategory: false,
    hallID: false,
  });

  const [seatingData, setSeatingData] = useState<{
    [key: string]: { [key: string]: SeatingCell };
  } | null>(null);
  const [filteredSeatingPlans, setFilteredSeatingPlans] = useState<any[]>([]);
  const [seatingList, setSeatingList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false); // Loader state
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    halls_service.getHallsList().then((res) => {
      const hallsList = res.data.status.halls;
      if (hallsList?.length) {
        dispatch(setHallsList(hallsList));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    seating_plan_service.getSeatingPlansList().then((res) => {
      setSeatingList(res.data.status?.seating);
    });
  }, []);

  useEffect(() => {
    events_service.getEventsList().then((res) => {
      const eventsList = res.data.status.events;
      if (id) {
        const event = eventsList.find((event: any) => event.event_id === id);
        if (event) {
          setFormData({
            eventName_en: event.eventName_en,
            eventName_am: event.eventName_am,
            description_en: event.description_en,
            description_am: event.description_am,
            eventImage: event.imageurl,
            dateTime: event.dateTime,
            eventCategory: event.eventCategory,
            hallID: event.hallID,
            seatingPlanID: event.seatingPlanID || "",
            status: event.status || "Draft",
          });
          setImagePreview(event.imageurl);
          setSeatingData(JSON.parse(event.seatmap));
          dispatch(setCurrentEvent(event));
          filterSeatingPlans(event.hallID);
        }
      }
    });
  }, [id, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files![0] : value,
    }));
    if (name === "hallID") {
      filterSeatingPlans(value);
    }
    if (type === "file" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const filterSeatingPlans = (hallID: string) => {
    const filteredPlans = seatingList?.filter((plan) => plan.hall_id === hallID);
    setFilteredSeatingPlans(filteredPlans);
    if (filteredPlans?.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        seatingPlanID: filteredPlans[0].seating_id,
      }));
      setSeatingData(JSON.parse(filteredPlans[0]?.seating_json));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        seatingPlanID: "",
      }));
      // setSeatingData(null);
    }
  };

  const handleSeatingPlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      seatingPlanID: value,
    }));
    const selectedPlan = filteredSeatingPlans.find(
      (plan) => plan.seating_id === value
    );
    if (selectedPlan) {
      setSeatingData(JSON.parse(selectedPlan.seating_json));
    }
  };

  const handleSeatingDataChange = (updatedSeatingData: {
    [key: string]: { [key: string]: SeatingCell };
  }) => {
    setSeatingData(updatedSeatingData);
  };

  const handleSubmit = () => {
    setLoading(true); // Activate loader
    // Updated error checks
    const newErrors = {
      eventName_en: formData.eventName_en === "",
      eventName_am: formData.eventName_am === "",
      dateTime: formData.dateTime === "",
      eventCategory: formData.eventCategory === "",
      hallID: formData.hallID === "",
    };
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("eventName_en", formData.eventName_en);
      formDataToSubmit.append("eventName_am", formData.eventName_am);
      formDataToSubmit.append("description_en", formData.description_en);
      formDataToSubmit.append("description_am", formData.description_am);
      formDataToSubmit.append("dateTime", formData.dateTime);
      formDataToSubmit.append("eventCategory", formData.eventCategory);
      formDataToSubmit.append("hallID", formData.hallID);
      formDataToSubmit.append("seatingPlanID", formData.seatingPlanID);
      formDataToSubmit.append("status", formData.status);
      formDataToSubmit.append("seatmap", JSON.stringify(seatingData || {}));
      if (formData.eventImage && typeof formData.eventImage === "object") {
        formDataToSubmit.append("eventImage", formData.eventImage);
      }

      if (id) {
        // Update the event if id exists in the path
        events_service
          .updateEvent({
            event_id: id,
            ...formData,
            seatmap: JSON.stringify(seatingData),
          })
          .then((res) => {
            setLoading(false); // Deactivate loader
            if (res.status === 200) {
              toast.success("Event updated successfully");
              setTimeout(() => {
                navigate("/events");
              }, 3000);
            } else {
              toast.error("Failed to update event");
            }
          });
      } else {
        // Create a new event if no id exists in the path
        events_service.createEvent(formDataToSubmit).then((res) => {
          setLoading(false); // Deactivate loader
          if (res.status === 200) {
            toast.success("Event created successfully");
            setTimeout(() => {
              navigate("/events");
            }, 3000);
          } else {
            toast.error("Failed to create event");
          }
        });
      }
    } else {
      setLoading(false); // Deactivate loader if there are validation errors
    }
  };

  return (
    <Box className={classes.root}>
      <Backdrop className={classes.loaderBackdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.container}>
        <Box className={classes.buttonContainer}>
          <Typography variant="body1">
            {id ? "Edit Event" : "Add New Event"}
          </Typography>
          <AddEventButton handleSubmit={handleSubmit} />
        </Box>
        <Box className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Event Image</Typography>
              <TextField
                type="file"
                variant="outlined"
                className={classes.textField}
                name="eventImage"
                onChange={handleChange}
                size="small"
              />
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="Event"
                  className={classes.imagePreview}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Date & Time</Typography>
              <TextField
                type="datetime-local"
                variant="outlined"
                className={classes.textField}
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                required
                error={errors.dateTime}
                helperText={errors.dateTime && "Date & Time is required"}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Event Category</Typography>
              <TextField
                select
                placeholder="Enter event Category"
                variant="outlined"
                className={classes.selectField}
                name="eventCategory"
                value={formData.eventCategory}
                onChange={handleChange}
                required
                error={errors.eventCategory}
                helperText={
                  errors.eventCategory && "Event Category is required"
                }
                size="small"
              >
                <MenuItem value="Concert">Concert</MenuItem>
                <MenuItem value="Exhibition">Exhibition</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Status</Typography>
              <TextField
                select
                variant="outlined"
                className={classes.selectField}
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
                size="small"
              >
                <MenuItem value="Draft">Draft</MenuItem>
                <MenuItem value="Publish">Publish</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Hall ID</Typography>
              <TextField
                select
                variant="outlined"
                className={classes.selectField}
                name="hallID"
                value={formData.hallID}
                onChange={handleChange}
                required
                error={errors.hallID}
                helperText={errors.hallID && "Hall ID is required"}
                size="small"
              >
                {hallsList.map((hall) => (
                  <MenuItem key={hall.hall_id} value={hall.hall_id}>
                    {language === "en" ? hall.hallName_en : hall.hallName_am}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {filteredSeatingPlans?.length > 0 && (
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">Seating Plan</Typography>
                <TextField
                  select
                  variant="outlined"
                  className={classes.selectField}
                  name="seatingPlanID"
                  value={formData.seatingPlanID}
                  onChange={handleSeatingPlanChange}
                  required
                  size="small"
                >
                  {filteredSeatingPlans.map((plan) => {
                    return (
                      <MenuItem key={plan.seating_id} value={plan.seating_id}>
                        {plan.seating_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className={classes.languageTabs}>
                <Tabs
                  value={language}
                  onChange={(e, newValue) => setLanguage(newValue)}
                  aria-label="language tabs"
                >
                  <Tab
                    icon={
                      <ReactCountryFlag
                        countryCode="US"
                        svg
                        style={{ marginRight: 10 }}
                      />
                    }
                    value="en"
                    label="English"
                  />
                  <Tab
                    icon={
                      <ReactCountryFlag
                        countryCode="AM"
                        svg
                        style={{ marginRight: 10 }}
                      />
                    }
                    value="am"
                    label="Armenian"
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {(errors.eventName_en || errors.eventName_am) && (
                <Typography className={classes.errorText}>
                  Please fill out all required fields in all languages.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                {language === "en"
                  ? "Event Name (English)"
                  : "Միջոցառման անվանումը (հայերեն)"}
              </Typography>
              <TextField
                placeholder={
                  language === "en"
                    ? "Enter event name in English"
                    : "Մուտքագրեք միջոցառման անունը հայերեն"
                }
                variant="outlined"
                className={classes.textField}
                name={`eventName_${language}`}
                value={formData[`eventName_${language}`]}
                onChange={handleChange}
                required
                error={errors[`eventName_${language}`]}
                helperText={
                  errors[`eventName_${language}`] && "Event Name is required"
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                {language === "en"
                  ? "Description (English)"
                  : "Նկարագրություն (հայերեն)"}
              </Typography>
              <TextField
                placeholder={
                  language === "en"
                    ? "Enter description in English"
                    : "Մուտքագրեք նկարագրությունը հայերեն"
                }
                variant="outlined"
                className={classes.textField}
                name={`description_${language}`}
                value={formData[`description_${language}`]}
                onChange={handleChange}
                multiline
                rows={4}
                size="small"
              />
            </Grid>
          </Grid>
          {seatingData && (
            <EventSeatingPlanTable
              seatingData={seatingData}
              onSeatingDataChange={handleSeatingDataChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const AddEventButton: React.FC<{ handleSubmit: () => void }> = ({
  handleSubmit,
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.addButton}
      size="small"
      onClick={handleSubmit}
    >
      Save
    </Button>
  );
};

export default EventsCreationPage;