import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  makeStyles,
  Typography,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import SeatingPlanTable from "../../Components/SeatingPlanComponents/SeatingPlanTable";
import swal from "sweetalert";
import seating_plan_service from "../../services/seating_plan_service";
import { setHallsList } from "../../reducers/hall_reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useParams, useLocation } from "react-router-dom";
import halls_service from "../../services/halls_service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import SeatingPlanTableNew from "../../Components/SeatingPlanComponents/SeatingPlanTableNew";
import { data_example } from "./data_example";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    paddingBottom: theme.spacing(2),
    overflow: "hidden",
  },
  container: {
    width: "98%",
    backgroundColor: "#fff",
    padding: "10px",
    height: "95%",
    overflowY: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#4D67EB",
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3755a3",
    },
  },
  selectContainer: {
    marginBottom: theme.spacing(2),
    width: "40%",
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
    width: "90%",
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 40,
  },
  selectLabel: {
    marginBottom: theme.spacing(1),
    fontSize: "18px",
  },
  inputLabel: {
    marginBottom: theme.spacing(1),
    fontSize: "18px",
  },
  borderTopBox: {
    borderTop: "1px solid #E3E3E3",
    paddingTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
  },
  circle: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  menuItemOpt: {
    display: "flex",
  },
  applyButton: {
    backgroundColor: "#3CAF2C",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2E8B22",
    },
    marginRight: theme.spacing(2),
  },
  selector: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  cancelButton: {
    backgroundColor: "#757575",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#5A5A5A",
    },
  },
  eachOptionBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    width: "100%",
  },
  loaderBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const valueColorForHall: { [key: number]: string } = {
  1: "#4D67EB",
  2: "#FF5347",
  3: "#FFC107",
  4: "#8E24AA",
  5: "#00ACC1",
};

interface SeatingCell {
  price: number;
  status: "open" | "invitation" | "closed";
  ordered: "yes" | "no";
  category: string;
}

interface SelectedCells {
  [key: number]: {
    [key: number]: SeatingCell;
  };
}

const CreateSeatingPlansPage: React.FC = () => {
  const classes = useStyles();
  const { id, seating_id } = useParams<{ id: string; seating_id?: string }>();
  const { state } = useLocation() as {
    state: {
      appliedCells: SelectedCells;
      seatingPlanId?: string;
      seatingPlanName?: string;
    };
  };
  const [selectedValue, setSelectedValue] = useState<number>(1);
  const [circleColor, setCircleColor] = useState<string>("#4D67EB");
  const [price, setPrice] = useState<number>(0);
  const [selectedHall, setSelectedHall] = useState<any>(null);
  const [seatingPlanName, setSeatingPlanName] = useState<string>("");
  const [selectedCells, setSelectedCells] = useState<SelectedCells>({});
  const [appliedCells, setAppliedCells] = useState<SelectedCells>(
    state?.appliedCells || data_example
  );
  const [seatingPlanId, setSeatingPlanId] = useState<string | undefined>(
    seating_id || state?.seatingPlanId
  );
  const [loading, setLoading] = useState(true); // Loader state
  const dispatch = useDispatch();
  const { hallsReducer } = useSelector((state: RootState) => state);
  const { hallsList } = hallsReducer;
  const { i18n } = useTranslation();
  const language = i18n.language;
  useEffect(() => {}, []);
  useEffect(() => {
    setSeatingPlanName(state?.seatingPlanName || "");
    halls_service
      .getHallsList()
      .then((res: any) => {
        const hallsList = res.data.status.halls;
        if (hallsList.length) {
          dispatch(setHallsList(hallsList));
          const hall = hallsList.find((hall: any) => hall.hall_id === id);
          setSelectedHall(hall || {});
        }
        setLoading(false); // Deactivate loader after data is fetched
      })
      .catch(() => {
        setLoading(false); // Deactivate loader in case of error
      });
  }, [dispatch, id]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number;
    setSelectedValue(value);
    setCircleColor(valueColorForHall[value] || "");
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(event.target.value));
  };

  const handleSeatingPlanNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSeatingPlanName(event.target.value);
  };

  const applyChanges = () => {
    setAppliedCells((prev) => {
      const newAppliedCells: SelectedCells = { ...prev };
      Object.keys(selectedCells).forEach((rowIndex) => {
        if (!newAppliedCells[+rowIndex]) {
          newAppliedCells[+rowIndex] = {};
        }
        Object.keys(selectedCells[+rowIndex]).forEach((colIndex) => {
          newAppliedCells[+rowIndex][+colIndex] =
            selectedCells[+rowIndex][+colIndex];
        });
      });
      return newAppliedCells;
    });
    setSelectedCells({});
    toast.success("Price applied successfully!");
  };

  const cancelSelection = () => {
    setSelectedCells({});
    toast.info("Selection cancelled.");
  };

  const handleSave = () => {
    const payload = seatingPlanId
      ? {
          seating_id: seatingPlanId,
          hall_id: id,
          seating_name: seatingPlanName,
          seating_json: JSON.stringify(appliedCells),
        }
      : {
          hall_id: id,
          seating_name: seatingPlanName,
          seating_json: JSON.stringify(appliedCells),
        };

    const savePromise = seatingPlanId
      ? seating_plan_service.updateSeatingPlan(payload)
      : seating_plan_service.createSeatingPlan(payload);
    // savePromise
    //   .then((res) => {
    //     swal("Success", "Seating plan saved successfully!", "success");
    //   })
    //   .catch((err) => {
    //     swal("Error", "Failed to save seating plan.", "error");
    //   });
  };

  return (
    <Box className={classes.root}>
      <ToastContainer />
      <Backdrop className={classes.loaderBackdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.container}>
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.addButton}
            size="small"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        <Box className={classes.selectContainer}>
          <Typography className={classes.selectLabel}>
            Add New Seating Plan
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={selectedHall?.hallName_en || ""}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <Typography className={classes.inputLabel}>
            Seating plan Name
          </Typography>
          <TextField
            fullWidth
            placeholder="Default seating plan"
            variant="outlined"
            value={seatingPlanName}
            onChange={handleSeatingPlanNameChange}
            style={{ borderRadius: "4px", padding: "0px" }}
          />
        </Box>
        <Box className={classes.borderTopBox}>
          <FormControl variant="outlined" style={{ width: "30%" }}>
            <Select value={selectedValue} onChange={handleSelectChange}>
              <MenuItem value={1}>
                <Box className={classes.eachOptionBox}>
                  <span
                    className={classes.circle}
                    style={{ backgroundColor: "#4D67EB" }}
                  ></span>
                  <Box>Seat Category 1</Box>
                </Box>
              </MenuItem>
              <MenuItem value={2}>
                <Box className={classes.eachOptionBox}>
                  <span
                    className={classes.circle}
                    style={{ backgroundColor: "#FF5347" }}
                  ></span>
                  <Box>Seat Category 2</Box>
                </Box>
              </MenuItem>
              <MenuItem value={3}>
                <Box className={classes.eachOptionBox}>
                  <span
                    className={classes.circle}
                    style={{ backgroundColor: "#FFC107" }}
                  ></span>
                  <Box>Seat Category 3</Box>
                </Box>
              </MenuItem>
              <MenuItem value={4}>
                <Box className={classes.eachOptionBox}>
                  <span
                    className={classes.circle}
                    style={{ backgroundColor: "#8E24AA" }}
                  ></span>
                  <Box>Seat Category 4</Box>
                </Box>
              </MenuItem>
              <MenuItem value={5}>
                <Box className={classes.eachOptionBox}>
                  <span
                    className={classes.circle}
                    style={{ backgroundColor: "#00ACC1" }}
                  ></span>
                  <Box>Seat Category 5</Box>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="number"
            variant="outlined"
            placeholder="Price"
            value={price}
            onChange={handlePriceChange}
            style={{ width: "20%" }}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.applyButton}
            onClick={applyChanges}
          >
            Apply Price
          </Button>
          <Button
            variant="contained"
            className={classes.cancelButton}
            onClick={cancelSelection}
          >
            Cancel Selection
          </Button>
        </Box>
        <Box className={classes.tableContainer}>
          <SeatingPlanTableNew
            seatColor={circleColor}
            price={price}
            selectedCells={selectedCells}
            setSelectedCells={setSelectedCells}
            appliedCells={appliedCells}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSeatingPlansPage;
